import React from "react"
import styled from "styled-components"
import {
  standardWrapper,
  H2DarkGrey,
  B1DarkGrey,
} from "../../../styles/helpers"

const SimpleSectionStyled = styled.section`
  .wrapper {
    ${standardWrapper};
    max-width: 92.7rem;
  }

  h2 {
    ${H2DarkGrey};
    text-align: center;
  }

  p {
    ${B1DarkGrey};
    text-align: center;
  }
`

const SimpleSection = ({ simSection }) => {
  return (
    <SimpleSectionStyled>
      <div className="wrapper">
        <div>
          <h2>{simSection.acf._kon_simconsec_title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: simSection.acf._kon_simconsec_content,
            }}
          />
        </div>
      </div>
    </SimpleSectionStyled>
  )
}

export default SimpleSection
