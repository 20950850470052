import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { medWrapper } from "../../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Visulizations from "../../Images/Visualizations"
import VisAccounting from "../../Icons/VisAccounting"
import VisBudgeting from "../../Icons/VisBudgeting"
import VisOperational from "../../Icons/VisOperational"
import VisOther from "../../Icons/VisOther"
import VisStatistics from "../../Icons/VisStatistics"
import VisTabular from "../../Icons/VisTabular"
import VisVisualizations from "../../Icons/VisVisualizations"

gsap.registerPlugin(ScrollTrigger)

const VisDataSection = styled.div`
  text-align: center;

  .wrapper {
    position: relative;
    width: 100%;
    max-width: calc(20rem + 4rem);
    margin: 15rem auto 20rem;
    padding: 2rem;

    @media (min-width: 768px) {
      width: 100%;
      max-width: calc(30rem + 4rem);
      margin: 20rem auto 10rem;
      padding: 2rem;
    }
  }

  .mainIcon {
    width: 100%;
    margin: auto;
  }

  .icons {
    position: absolute;
    width: 15rem;

    @media (min-width: 768px) {
      width: 22.5rem;
    }
    @media (min-width: 1025px) {
      width: 27.5rem;
    }
  }

  .iconsOne {
    top: -2.5rem;
    left: -7.5rem;

    @media (min-width: 768px) {
      top: -5rem;
      left: -15rem;
    }

    @media (min-width: 1025px) {
      top: -5rem;
      left: -25rem;
    }
  }

  .iconsTwo {
    top: 50%;
    left: -13rem;
    transform: translate(0, -50%);

    @media (min-width: 768px) {
      top: 50%;
      left: -22.5rem;
    }

    @media (min-width: 1025px) {
      top: 50%;
      left: -32rem;
    }
  }

  .iconsThree {
    bottom: -2.5rem;
    left: -7.5rem;

    @media (min-width: 768px) {
      bottom: -5rem;
      left: -15rem;
    }

    @media (min-width: 1025px) {
      bottom: -5rem;
      left: -28rem;
    }
  }

  .iconsFour {
    top: -10rem;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: 768px) {
      top: -16rem;
      left: 50%;
      transform: translate(-50%, 0);
    }

    @media (min-width: 1025px) {
      top: -15rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .iconsFive {
    bottom: -2.5rem;
    right: -8rem;

    @media (min-width: 768px) {
      bottom: -5rem;
      right: -16rem;
    }

    @media (min-width: 1025px) {
      bottom: -5rem;
      right: -28rem;
    }
  }

  .iconsSix {
    top: 50%;
    right: -13rem;
    transform: translate(0, -50%);

    @media (min-width: 768px) {
      top: 50%;
      right: -23rem;
      transform: translate(0, -50%);
    }

    @media (min-width: 1025px) {
      top: 50%;
      right: -32rem;
      transform: translate(0, -50%);
    }
  }

  .iconsSeven {
    top: -2.5rem;
    right: -7.5rem;

    @media (min-width: 768px) {
      top: -5rem;
      right: -15rem;
    }

    @media (min-width: 1025px) {
      top: -5rem;
      right: -25rem;
    }
  }
`

const VisData = () => {
  useEffect(() => {
    const triggerElement = document.querySelector("#visDataSec")
    const allBubbles = [...triggerElement.querySelectorAll(".icons")]

    gsap.set(allBubbles[0], {
      x: 200,
      y: -100,
      scale: 0.5,
      autoAlpha: 0,
    })
    gsap.set(allBubbles[1], {
      x: 200,
      y: 0,
      scale: 0.5,
      autoAlpha: 0,
    })
    gsap.set(allBubbles[2], {
      x: 200,
      y: 100,
      scale: 0.5,
      autoAlpha: 0,
    })
    gsap.set(allBubbles[3], {
      x: 0,
      y: 200,
      scale: 0.5,
      autoAlpha: 0,
    })
    gsap.set(allBubbles[4], {
      x: -200,
      y: 100,
      scale: 0.5,
      autoAlpha: 0,
    })
    gsap.set(allBubbles[5], {
      x: -250,
      y: 0,
      scale: 0.5,
      autoAlpha: 0,
    })
    gsap.set(allBubbles[6], {
      x: -200,
      y: -100,
      scale: 0.5,
      autoAlpha: 0,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 50%",
          toggleActions: "play none none reverse",
        },
      })
      .to(allBubbles, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        scale: 1,
        duration: 0.75,
        stagger: { each: 0.2, from: "center" },
      })
  }, [])

  return (
    <VisDataSection id="visDataSec">
      <div className="wrapper">
        <div className="mainIcon">
          <Visulizations />
        </div>

        <div className="icons iconsThree">
          <VisAccounting />
        </div>
        <div className="icons iconsTwo">
          <VisStatistics />
        </div>

        <div className="icons iconsOne">
          <VisTabular />
        </div>
        <div className="icons iconsFour">
          <VisVisualizations />
        </div>
        <div className="icons iconsSeven">
          <VisOther />
        </div>
        <div className="icons iconsSix">
          <VisOperational />
        </div>
        <div className="icons iconsFive">
          <VisBudgeting />
        </div>
      </div>
    </VisDataSection>
  )
}

export default VisData
