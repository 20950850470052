import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Intro from "../components/TemplateComponets/Solutions/Intro"
import ThreeColumns from "../components/TemplateComponets/Solutions/ThreeColumns"
import SimpleSection from "../components/TemplateComponets/Solutions/SimpleSection"
import VisData from "../components/TemplateComponets/Solutions/VisData"
import SimpleLogos from "../components/TemplateComponets/Solutions/SimpleLogos"
import WysiwygSec from "../components/TemplateComponets/Solutions/WysiwygSec"
import CallAction from "../components/TemplateComponets/Solutions/CallAction"

const solutionsTwo = props => {
  const {
    seoInfo,
    intro,
    threeColumns,
    simSection,
    simLogos,
    wysiwygSec,
    callAction,
  } = props.data
  const location = props.location
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <Intro intro={intro} />
      <ThreeColumns threeColumns={threeColumns} />
      <SimpleSection simSection={simSection} />
      <VisData />
      <SimpleLogos simLogos={simLogos} />
      <WysiwygSec wysiwygSec={wysiwygSec} />
      <CallAction callAction={callAction} />
    </Layout>
  )
}

export const solutionsTwoQuery = graphql`
  query solutionsTwoPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    intro: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_soluin_title
        _kon_soluin_content
        _kon_soluin_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    threeColumns: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_tcccon_content_items {
          title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    simSection: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_simconsec_title
        _kon_simconsec_content
      }
    }

    simLogos: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_simlogos_logos {
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        _kon_simlogos_title
        _kon_simlogos_button_text
        _kon_simlogos_button_url
      }
    }

    wysiwygSec: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_solutions_wysiwyg_section
      }
    }

    callAction: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_call_action_title
        _kon_call_action_sub_title
        _kon_call_action_points {
          point
        }
        _kon_call_action_button_text
        _kon_call_action_button_link
        _kon_call_action_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default solutionsTwo
