import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import {
  standardWrapper,
  H2DarkGrey,
  Btn1LimeGreen,
} from "../../../styles/helpers"

const SimpleLogosSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .logo {
    width: calc(50% - 5rem);
    margin: 0 2.5rem;

    @media (min-width: 768px) {
      width: calc(33.33% - 5rem);
      margin: 2.5rem;
    }

    @media (min-width: 1025px) {
      width: calc(20% - 5rem);
      margin: 2.5rem;
    }
  }

  .title,
  .button {
    width: 100%;
    text-align: center;
  }

  .button {
    margin-top: 2.5rem;
  }

  h2 {
    ${H2DarkGrey};
  }

  a {
    ${Btn1LimeGreen};
  }
`

const SimpleLogos = ({ simLogos }) => {
  return (
    <SimpleLogosSection>
      <div className="wrapper">
        <div className="logos">
          {simLogos.acf._kon_simlogos_logos.map((logo, index) => {
            return (
              <div key={index} className="logo">
                <Img
                  fluid={logo.logo.localFile.childImageSharp.fluid}
                  alt={logo.logo.alt_text}
                ></Img>
              </div>
            )
          })}
        </div>
        <div className="title">
          <h2>{simLogos.acf._kon_simlogos_title}</h2>
        </div>
        <div className="button">
          <a target="_blank" href={simLogos.acf._kon_simlogos_button_url}>
            {simLogos.acf._kon_simlogos_button_text}
          </a>
        </div>
      </div>
    </SimpleLogosSection>
  )
}

export default SimpleLogos
